import { receivedProductByLookKey, receivedProducts } from '@/actions/types/ProductActionTypes';
import { AnyAction } from '@reduxjs/toolkit';
import type { WProductView } from '@zola/svc-web-api-ts-client';

type ProductState = {
  products: WProductView[] | undefined;
  defaultGiftCard: WProductView | null;
};
const initialState: ProductState = {
  products: undefined,
  defaultGiftCard: null,
};
const productReducer = (state = initialState, action: AnyAction): ProductState => {
  if (receivedProductByLookKey.match(action)) {
    return { ...state, defaultGiftCard: action.payload };
  }
  if (receivedProducts.match(action)) {
    return { ...state, products: action.payload };
  }

  return state;
};

export default productReducer;
