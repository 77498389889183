import ClientApp from '../../src/ClientApp';
import RegistryRoutes from '../../src/routes/RegistryRoutes';

const RegistryRoutesClient = () => (
  <ClientApp>
    <RegistryRoutes />
  </ClientApp>
);

export default RegistryRoutesClient;
