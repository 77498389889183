import { createAction } from '@reduxjs/toolkit';
import type { WProductView } from '@zola/svc-web-api-ts-client';

const REQUEST_DEFAULT_GIFT_CARD_BY_LOOK_KEY = 'zola/overview/REQUEST_DEFAULT_GIFT_CARD_BY_LOOK_KEY';
const RECEIVED_DEFAULT_GIFT_CARD_BY_LOOK_KEY =
  'zola/overview/RECEIVED_DEFAULT_GIFT_CARD_BY_LOOK_KEY';
const REQUEST_PRODUCTS = 'zola/overview/REQUEST_PRODUCTS';
const RECEIVED_PRODUCTS = 'zola/overview/RECEIVED_PRODUCTS';

export const requestProductByLook = createAction(REQUEST_DEFAULT_GIFT_CARD_BY_LOOK_KEY);

export const receivedProductByLookKey = createAction<WProductView>(
  RECEIVED_DEFAULT_GIFT_CARD_BY_LOOK_KEY
);

export const requestProducts = createAction(REQUEST_PRODUCTS);

export const receivedProducts = createAction<WProductView[]>(RECEIVED_PRODUCTS);
