import PropTypes from 'prop-types';
import { Route, RouteProps } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@/store';
import useEffectOnce from '@zola/zola-ui/src/hooks/useEffectOnce';
import { getUserContext } from '../../actions/UserActions';

interface UserContextRouteProps extends RouteProps {
  auth?: boolean;
  hasRegistry?: boolean;
  hasRegistryAfterAuth?: boolean;
}

/**
 * Helper component that blocks rendering of a routed component until the UserContext is loaded
 * If the auth flag is turned on, any user don't have registry will be redirect to the login page
 * @param auth {string} should check has_registry flag
 * @param hasRegistry {string} (Deprecated) User must have a registry account. Checked BEFORE login.
 * @param hasRegistryAfterAuth {string} user must have a registry account. Checked AFTER login.
 * @param RoutedComponent {JSX} actual component that should be render
 * @param rest {Object} all the props that should be passed to RoutedComponent
 * @returns {*}
 * @constructor
 */
const UserContextRoute = ({
  auth,
  component: RoutedComponent,
  hasRegistry,
  hasRegistryAfterAuth,
  ...rest
}: UserContextRouteProps): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const userContext = useAppSelector((state) => state.user.userContext);
  useEffectOnce(() => {
    dispatch(getUserContext());
  });

  if (!RoutedComponent) {
    return null;
  }

  // route guard
  if (auth && userContext) {
    // Deprecated check. Non-authenticated users should be allowed to log in before being forced to onboarding.
    if (hasRegistry && !userContext.has_registry) {
      window.location.href = `/onboard/new`;
      return null;
    }

    if (userContext.is_guest) {
      // Return user to the same path if auth is required
      const returnToPath = encodeURIComponent(window.location.pathname + window.location.search);
      window.location.href = `/account/login?returnTo=${returnToPath}`;
      return null;
    }

    if (hasRegistryAfterAuth && !userContext.has_registry) {
      window.location.href = `/onboard/new`;
      return null;
    }
  }

  return <Route {...rest} render={(props) => userContext && <RoutedComponent {...props} />} />;
};

UserContextRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  hasRegistry: PropTypes.bool,
};

export default UserContextRoute;
