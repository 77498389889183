import moment from 'moment/moment';

/**
 * helper function for get event date information text. Variations includes,
 * 1.) (Date coming soon)
 * 2.) January 1, 2019 (Today)
 * 3.) January 1, 2019 (1 day left)
 * 4.) January 1, 2019 (x day left), x being a number
 */
export const getEventDateMessage = (eventDate?: string | number): string => {
  if (!eventDate) {
    return 'Date coming soon';
  }

  const targetDate = moment.utc(eventDate);
  const timezoneOffset = new Date().getTimezoneOffset() / 60;
  const hoursDiff = moment.utc().diff(targetDate, 'hours') - timezoneOffset;
  const daysToWedding = -Math.floor(hoursDiff / 24);
  const daysToWeddingText =
    daysToWedding === 1 ? ` (${daysToWedding} day left)` : ` (${daysToWedding} days left)`;
  const dayLeftText = daysToWedding === 0 ? '(Today)' : daysToWeddingText;
  const formattedDateMessage = targetDate.format('MMMM DD, YYYY');
  const eventDateMessage =
    daysToWedding < 0 ? `${formattedDateMessage}` : `${formattedDateMessage} ${dayLeftText}`;

  return eventDateMessage;
};

/**
 *
 * @param collections - Any array
 * @param current
 * @param target
 * @returns  new array
 *
 * Example:
 * collections: [1, 2, 3, 4] current: 0 target: 2 returns: [2, 3, 1, 4]
 */
export const arrangeCollections = <T>(collections: T[] = [], current: number, target: number) => {
  const newCollection = [...collections];
  const [currentItem] = newCollection.splice(current, 1);
  newCollection.splice(target, 0, currentItem);

  return newCollection;
};
